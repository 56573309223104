<template>
  <Layout :tituloPagina="`Hotspot | Fichas | Tipos | ${modo == 'nuevo' ? 'Nuevo': 'Edición'}`">
    <div class="row">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo'?'Nuevo':'Editar'}} tipo de fichas
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <input
                  name="descripcion"
                  ref="descripcion"
                  class="form-control"
                  placeholder="Descripción del tipo de ficha"
                  v-model="ficha_tipo.descripcion"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-4">
                <label>Precio</label>
                <div class="input-group">
                  <span class="input-group-text">
                    {{ monedaSistema.simbolo }}
                  </span>
                  <input
                    name="precio"
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="ficha_tipo.precio"
                  />
                  <span class="input-group-text">
                    {{ monedaSistema.codigo }}
                  </span>
                </div>
              </div>
              <div class="col-md-4">
                <label>Perfil de usuario</label>
                <select
                  name="perfil"
                  ref="perfil"
                  class="form-select"
                  v-model="ficha_tipo.id_perfil"
                  @change="cargarPerfil()"
                >
                  <option :value="null">Seleccionar</option>
                  <option :value="-1">Nuevo perfil de usuario</option>
                  <option v-for="perfil in perfiles" :key="perfil.id" :value="perfil.id">{{perfil.nombre}}</option>
                </select>
              </div>
              <div class="col-md-4 pt-4">
                <div class="d-grid gap-2">
                  <router-link
                    :to="{ name: 'perfilesHotspot' }"
                    class="btn btn-outline-success"
                  >
                    <i class="mdi mdi-format-list-bulleted"></i>
                    Perfiles de usuario
                  </router-link>
                </div>
              </div>
            </div>
            
            <hr>

            <div class="row" v-show="ficha_tipo.id_perfil == -1">
              <div class="col-md-6">
                <label>Nombre del perfil</label>
                <input
                  class="form-control"
                  type="text"
                  name="nombre"
                  ref="nombre"
                  v-model="perfil.nombre"
                  placeholder="Ingrese un nombre"
                />
              </div>
            </div>
            
            <br v-show="ficha_tipo.id_perfil == -1">

            <div class="row">
              <div class="col-md-6">
                <label>Velocidad de subida</label>
                <sup 
                  :class="ficha_tipo.id_perfil == -1 ? 'text-info' : ''"
                  v-show="perfil.velocidad_carga == 0"
                >
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="perfil.velocidad_carga"
                    @change="verificarVelicidadCarga()"
                    name="velocidadCarga"
                    ref="velocidadCarga"
                    :disabled="ficha_tipo.id_perfil != -1"
                    min="0"
                  />
                  <select
                    v-model="perfil.tipo_velocidad_carga"
                    @change="verificarVelicidadCarga()"
                    class="form-select"
                    name="tipoVelocidadCarga"
                    :disabled="ficha_tipo.id_perfil != -1"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                  
                </div>
              </div>
              <div class="col-md-6">
                <label>Velocidad de bajada</label>
                <sup
                  :class="ficha_tipo.id_perfil == -1 ? 'text-info' : ''"
                  v-show="perfil.velocidad_descarga == 0"
                >
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="perfil.velocidad_descarga"
                    @change="verificarVelicidadDescarga()"
                    name="velocidadDescarga"
                    ref="velocidadDescarga"
                    min="0"
                    :disabled="ficha_tipo.id_perfil != -1"
                  />
                  <select
                    v-model="perfil.tipo_velocidad_descarga"
                    @change="verificarVelicidadDescarga()"
                    class="form-control"
                    name="tipoVelocidadCarga"
                    :disabled="ficha_tipo.id_perfil != -1"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-4" style="border-right: #E5E6E7 1px solid;">
                <label>Tiempo limite de ficha</label>
                <div class="row text-center">
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoDias" type="number"
                      class="form-control" ref="tiempoDias"
                      v-model="perfil.tiempo_fichas_dias"
                      min="0" max="365"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Dias</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoHoras" type="number"
                      class="form-control" ref="tiempoHoras"
                      v-model="perfil.tiempo_fichas_horas"
                      min="0" max="23"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Horas</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoMinutos" type="number"
                      class="form-control" ref="tiempoMinutos"
                      v-model="perfil.tiempo_fichas_minutos" 
                      min="0" max="59"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Minutos</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4" style="border-right: #E5E6E7 1px solid;">
                <label>Ficha expira en</label>
                <div class="row text-center">
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionDias" type="number"
                      class="form-control" ref="expiracionDias"
                      v-model="perfil.expiracion_dias"
                      min="0" max="365"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Dias</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionHoras" type="number"
                      class="form-control" ref="expiracionHoras"
                      v-model="perfil.expiracion_horas"
                      min="0" max="23"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Horas</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionMinutos" type="number"
                      class="form-control" ref="expiracionMinutos"
                      v-model="perfil.expiracion_minutos"
                      min="0" max="59"
                      :disabled="ficha_tipo.id_perfil != -1"
                    />
                    <label>Minutos</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Limite de trafico</label>
                <div class="row">
                  <div class="col-sm-4 col-4">
                    <div class="form-check form-switch form-switch-lg">
                      <input
                        class="form-check-input" type="checkbox"
                        id="limite-trafico"
                        :disabled="ficha_tipo.id_perfil != -1"
                        :checked="perfil.limite_trafico"
                        @click="
                          perfil.limite_trafico = !perfil.limite_trafico,
                          perfil.limite_trafico_total = 0,
                          perfil.tipo_limite_trafico_total = 'k'
                        "
                      />
                      <label class="form-check-label" for="limite-trafico"></label>
                    </div>
                  </div>
                  <div class="col-sm-8 col-8">
                    <div class="input-group">
                      <input
                        name="limiteTrafico"
                        type="number"
                        ref="limiteTrafico"
                        class="form-control"
                        v-model="perfil.limite_trafico_total"
                        :disabled="ficha_tipo.id_perfil != -1 || !perfil.limite_trafico"
                        min="0"
                        :max="maxLimiteTotal(perfil.tipo_limite_trafico_total)"
                      />
                      <select
                        :disabled="ficha_tipo.id_perfil != -1 || !perfil.limite_trafico"
                        v-model="perfil.tipo_limite_trafico_total"
                        class="form-control"
                        name="tipoVelocidadCarga"
                      >
                        <option value="k">KB</option>
                        <option value="M">MB</option>
                        <option value="G">GB</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-3">
                <label>Usuarios compartidos</label>
                <input
                  style="min-width: 34px;"
                  name="limiteTrafico"
                  placeholder="0"
                  type="number"
                  class="form-control"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="perfil.usuarios_compartidos"
                  :disabled="ficha_tipo.id_perfil != -1"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button @click="cerrar()" class="btn btn-secondary">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="modo=='nuevo' ? guardar():actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{modo=='nuevo' ? 'Guardar':'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'
import PerfilSrv from '@/services/hotspot/PerfilSrv.js'
import Layout from "@/views/layouts/main"
export default {
  name: 'EdicionFichaTipo',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      ficha_tipo: {
        descripcion: '',
        id_perfil: null,  // si el valor es -1 indica que se va registrar un nuevo perfil de usuario
        precio: 0,
      },
      perfiles: [],
      perfil:{
        id: null,
        nombre: '',

        velocidad_carga: 0,
        tipo_velocidad_carga: 'k',

        velocidad_descarga: 0,
        tipo_velocidad_descarga: 'k',

        tiempo_fichas_dias: 0,
        tiempo_fichas_horas: 0,
        tiempo_fichas_minutos: 0,

        expiracion_dias: 0,
        expiracion_horas: 0,
        expiracion_minutos: 0,

        limite_trafico: false,
        limite_trafico_total: 0,
        tipo_limite_trafico_total: 'k',
        usuarios_compartidos: 1
      },
      bandera_spinner: false
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function(){
    var self = this

    // Determinación del modo
    if (self.$route.path.indexOf('tipos/nuevo') != -1) self.modo = 'nuevo'
    else self.modo = 'edicion'

    switch (self.modo) {
      case 'nuevo':
        self.cargarPerfiles()
      break;
      case 'edicion':
        self.cargarPerfiles()
        self.cargarFichaTipo()
      break;
    }
  },

  methods: {
    actualizar: function() {
      var self = this,
          ficha_tipo = Object.assign({}, self.ficha_tipo)
      
      self.bandera_spinner = true

      if (ficha_tipo.descripcion == '') {
        iu.msg.warning('Es necesario registrar una descripción')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return
      }

      if (ficha_tipo.id_perfil == null) {
        iu.msg.warning('Es necesario seleccionar un perfil')
        self.$refs.perfil.focus()
        self.bandera_spinner = false
        return
      }

      if(ficha_tipo.id_perfil == -1) self.guardarPerfil(ficha_tipo, 2)
      else self.actualizarTipo(ficha_tipo)
    },

    actualizarTipo: function(ficha_tipo) {
      var self = this
            
      FichaTipoSrv.actualizar(ficha_tipo).then(response => {
        iu.msg.success('La ficha de tipo se actualizo correctamente')
        
        setTimeout(() => {
          self.cargarPerfiles()
          self.cargarFichaTipo()
        }, 200);
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo actualizar la ficha de tipo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarFichaTipo: function(){
      var self = this,
        idFicha = self.$route.params.id

      FichaTipoSrv.fichaTipoJSON(idFicha).then(response => {
        self.ficha_tipo = response.data
        self.cargarPerfil()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo cargar la ficha de tipo'
        }
        iu.msg.error(mensaje)
      })
    },

    cargarPerfiles: function() {
      var self = this

      PerfilSrv.perfilesJSON().then(response => {
        self.perfiles = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se cargaron los perfiles para hotspot'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    
    perfilDefault: function () {
      var self = this

      self.perfil = {
        id: null,
        nombre: '',

        velocidad_carga: 0,
        tipo_velocidad_carga: 'k',

        velocidad_descarga: 0,
        tipo_velocidad_descarga: 'k',

        tiempo_fichas_dias: 0,
        tiempo_fichas_horas: 0,
        tiempo_fichas_minutos: 0,

        expiracion_dias: 0,
        expiracion_horas: 0,
        expiracion_minutos: 0,

        limite_trafico: false,
        limite_trafico_total: 0,
        tipo_limite_trafico_total: 'k',

        usuarios_compartidos: 1
      }
    },

    cargarPerfil: function() {
      var self = this, idPerfil

      if(self.ficha_tipo.id_perfil == -1 || self.ficha_tipo.id_perfil == null) {
        self.perfilDefault()
        return
      } else {
        idPerfil = self.ficha_tipo.id_perfil
      }

      PerfilSrv.perfilJSON(idPerfil).then(response => {
        var perfil = response.data

        self.construiPerfil(perfil)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'Se ocasionó un problema y no se puede cargar el perfil seleccionado'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },
    
    convertidorBit_a_kMG:function(bites){
      var self = this

      var gb = Math.floor((bites / 1073741824))
      if(gb >= 1) {
        return [gb,'G']
      }

      var mb = Math.floor((bites / 1048576 ) % 1024)
      if(mb >= 1) {
        return [mb,'M']
      }

      var kb = Math.floor((bites / 1024 ) % 1024)
      if(kb >= 1) {
        return [kb,'k']
      }
    },

    construiPerfil: function(perfil) {
      var self = this, nuevo_perfil = {}

      var tipoVelocidadCarga = perfil.velocidad_carga.charAt(perfil.velocidad_carga.length - 1);
      var velocidadCarga =perfil.velocidad_carga.substr(0,perfil.velocidad_carga.length-1)

      if(tipoVelocidadCarga != 'k' && tipoVelocidadCarga != 'M') {
        tipoVelocidadCarga = 'k'
      }

      var tipoVelocidadDescarga = perfil.velocidad_descarga.charAt(perfil.velocidad_descarga.length - 1);
      var velocidadDescarga =perfil.velocidad_descarga.substr(0,perfil.velocidad_descarga.length-1)

      if(tipoVelocidadDescarga != 'k' && tipoVelocidadDescarga != 'M') {
        tipoVelocidadDescarga = 'k'
      }

      Object.assign(nuevo_perfil, {
        id: perfil.id,
        nombre: perfil.nombre,
        created_at: perfil.created_at,
        updated_at: perfil.updated_at,

        velocidad_carga: velocidadCarga,
        tipo_velocidad_carga: tipoVelocidadCarga,

        velocidad_descarga: velocidadDescarga,
        tipo_velocidad_descarga: tipoVelocidadDescarga,

        tiempo_fichas_dias: self.convertidorSegundos_a_DHM(perfil.tiempo_fichas )[0],
        tiempo_fichas_horas: self.convertidorSegundos_a_DHM(perfil.tiempo_fichas )[1],
        tiempo_fichas_minutos: self.convertidorSegundos_a_DHM(perfil.tiempo_fichas )[2],

        expiracion_dias: self.convertidorSegundos_a_DHM(perfil.expiracion)[0],
        expiracion_horas: self.convertidorSegundos_a_DHM(perfil.expiracion)[1],
        expiracion_minutos: self.convertidorSegundos_a_DHM(perfil.expiracion)[2],

        limite_trafico: perfil.limite_trafico_total != null ? true: false,
        limite_trafico_total: perfil.limite_trafico_total != null ? self.convertidorBit_a_kMG(perfil.limite_trafico_total)[0] : 0,
        tipo_limite_trafico_total: perfil.limite_trafico_total != null ? self.convertidorBit_a_kMG(perfil.limite_trafico_total)[1] : 'k',
        usuarios_compartidos: perfil.usuarios_compartidos
      })
      
      self.perfil = nuevo_perfil
    },

    guardar: function() {
      var self = this,
          ficha_tipo = Object.assign({}, self.ficha_tipo)
      
      self.bandera_spinner = true

      if (ficha_tipo.descripcion == '') {
        iu.msg.warning('Es necesario registrar una descripción')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return
      }

      if (ficha_tipo.id_perfil == null) {
        iu.msg.warning('Es necesario seleccionar un perfil')
        self.$refs.perfil.focus()
        self.bandera_spinner = false
        return
      }

      if(ficha_tipo.id_perfil == -1) self.guardarPerfil(ficha_tipo, 1)
      else self.guardarTipo(ficha_tipo)
    },

    guardarTipo: function(ficha_tipo){
      var self = this
      
      FichaTipoSrv.guardar(ficha_tipo).then(response => {
        iu.msg.success('La ficha de tipo se guardó correctamente')
        
        var id = response.data
        
        self.modo = 'edicion'
        
        self.$router.replace({
          name: 'edicionFichaTipoHotspot',
          params: {
            id: id
          }
        })

        setTimeout(() => {
          self.cargarPerfiles()
          self.cargarFichaTipo()
        }, 200);
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo guardar la ficha de tipo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    // modoGyuardado solo asepta dos tipos de datos 1 para guardado y 2 para actualizado
    guardarPerfil: function(ficha_tipo, modoGuardado) {
      var self = this

      if (!self.verificarDatosCorrectos()) return

      var perfil = {
        nombre: self.perfil.nombre,
        velocidad_carga: self.perfil.velocidad_carga+self.perfil.tipo_velocidad_carga,
        velocidad_descarga: self.perfil.velocidad_descarga+self.perfil.tipo_velocidad_descarga,
        tiempo_fichas: self.calcularDiasASegundos(self.perfil.tiempo_fichas_dias)+self.calcularHorasASegundos(self.perfil.tiempo_fichas_horas)+ self.calcularMinutosASegundos(self.perfil.tiempo_fichas_minutos),
        expiracion: self.calcularDiasASegundos(self.perfil.expiracion_dias)+self.calcularHorasASegundos(self.perfil.expiracion_horas)+self.calcularMinutosASegundos(self.perfil.expiracion_minutos),
        usuarios_compartidos: self.perfil.usuarios_compartidos
      }

      if(self.perfil.limite_trafico){
        Object.assign(perfil,{
          limite_trafico_total: this.calcularBits(self.perfil.limite_trafico_total,self.perfil.tipo_limite_trafico_total)
        })
      }
      
      PerfilSrv.guardar(perfil).then(response => {
        iu.msg.success("Se guardo el nuevo perfil correctamente")
        var id = response.data
        ficha_tipo.id_perfil = id
        
        if(modoGuardado == 1) self.guardarTipo(ficha_tipo)
        else if(modoGuardado == 2) self.actualizarTipo(ficha_tipo)

      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudo guardar el perfil'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
      
    },

    calcularBits: function(total, tipo){
      if(tipo == 'k') {
        return total * 1024
      }
      if(tipo == 'M') {
        return total * 1048576
      }
      if(tipo == 'G'){
        return total * 1073741824
      }
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600

    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },


    maxLimiteTotal:function(tipo){
      if(tipo == 'G') return '10240'
      if(tipo == 'M') return '10485760'
      if(tipo == 'k') return '10737418240'
    },

    verificarDatosCorrectos(){
      var self = this,
          perfil = self.perfil

      if(perfil.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.velocidad_carga < 0){
        iu.msg.warning('Se requiere que la velocidad de subida no sea menor a 0')
        self.$refs.velocidadCarga.focus()
        self.bandera_spinner = false
        return false
      }

      if (perfil.velocidad_carga % 1 != 0) {
        iu.msg.warning('La velocidad de subida no puede tener números decimales')
        self.$refs.velocidadCarga.focus()
        self.bandera_spinner = false
      }

      if(perfil.velocidad_descarga < 0){
        iu.msg.warning('Se requiere que la velocidad de bajada no sea menor a 0')
        self.$refs.velocidadDescarga.focus()
        self.bandera_spinner = false
        return false
      }

      if (perfil.velocidad_descarga % 1 != 0) {
        iu.msg.warning('La velocidad de bajada no puede tener números decimales')
        self.$refs.velocidadDescarga.focus()
        self.bandera_spinner = false
        return false
      }


      if(perfil.tiempo_fichas_dias > 365 || perfil.tiempo_fichas_dias < 0){
        iu.msg.warning('El tiempo no puede se mas de 365 dias o menor a cero dias')
        self.$refs.tiempoDias.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.tiempo_fichas_horas >= 23 || perfil.tiempo_fichas_horas < 0){
        iu.msg.warning('El tiempo no puede se mas de 23 horas o menor a cero horas')
        self.$refs.tiempoHoras.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.tiempo_fichas_minutos >= 59 || perfil.tiempo_fichas_minutos < 0){
        iu.msg.warning('El tiempo no puede se mas de 59 minutos o menor a cero minutos')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_dias > 365 || perfil.expiracion_dias < 0){
        iu.msg.warning('El expiración no puede se mas de 365 dias o menor a cero dias')
        self.$refs.expiracionHoras.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_horas >= 23 || perfil.expiracion_horas < 0){
        iu.msg.warning('El expiración no puede se mas de 23 horas o menor a cero horas')
        self.$refs.expiracionHoras.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_minutos >= 59 || perfil.expiracion_minutos < 0){
        iu.msg.warning('El expiración no puede se mas de 59 minutos o menor a cero minutos')
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'G' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para Gb no sea menos a 0 o mayor a 10240')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'M' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para Mb no sea menos a 0 o mayor a 10485760')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'k' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para kb no sea menos a 0 o mayor a 10737418240')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }
      return true
    },

    verificarVelicidadCarga() {
      var self = this

      if (self.perfil.velocidad_carga % 1 != 0) {
        if (self.perfil.tipo_velocidad_carga == "M") {
          self.perfil.velocidad_carga = self.perfil.velocidad_carga * 1000
          self.perfil.tipo_velocidad_carga = "k"
        } else {
          iu.msg.warning("La velocidad de subida no puede tener números decimales")
          self.perfil.velocidad_carga = Math.round(self.perfil.velocidad_carga)
        }
      }
    },

    verificarVelicidadDescarga() {
      var self = this

      if (self.perfil.velocidad_descarga % 1 != 0) {
        if (self.perfil.tipo_velocidad_descarga == "M") {
          self.perfil.velocidad_descarga = self.perfil.velocidad_descarga * 1000
          self.perfil.tipo_velocidad_descarga = "k"
        } else {
          iu.msg.warning("La velocidad de bajada no puede tener números decimales")
          self.perfil.velocidad_descarga = Math.round(self.perfil.velocidad_descarga)
        }
      }
    }
  }
}
</script>

<style scoped>
.pt-4 {
  padding-top: 1.8rem !important;
}
</style>